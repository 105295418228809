<template>
  <svg
    id="1619978a-3ca2-4ecc-aab1-d3e002df3c34"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 37 37"><path
      d="M7.37,0H29.63A7.38,7.38,0,0,1,37,7.38V29.63A7.37,7.37,0,0,1,29.63,37H7.37A7.37,7.37,0,0,1,0,29.63V7.37A7.37,7.37,0,0,1,7.37,0Z"
      class="icon-wrapper__template"
      :fill="color" /><path
        d="M10.44,12.34h5.64a1.69,1.69,0,0,0,0-3.37,2.9,2.9,0,0,0-2.19-2.32l-.07,0-.13,0-.37,0h-.09l-.36,0h-.14A2.89,2.89,0,0,0,10.4,9a1.69,1.69,0,0,0,0,3.37Z"
        transform="translate(-0.02 0)"
        fill="#fff" /><path
          d="M29.3,28.08H25.91V27.2L28,26.12h0a.64.64,0,0,0,.25-.84.57.57,0,0
          ,0-.8-.28l-1.57.8v-.93a4.3,4.3,0,0,0,2.76-1.63,4.53,4.53,0,0
          ,0-.73-6.2,3.27,3.27,0,0,0,0-3.16,3,3,0,0,0-4.14-1.16A3.23
          ,3.23,0,0,0,22.68,17a4.55,4.55,0,0,0-1.59,2.85,4.39,4.39,0,0
          ,0,3.61,5v3.2H18.3V26.32H19a.52.52,0,0,0,.52-.52V23.88a.52.52
          ,0,0,0-.52-.52H16.87V21.78H18.3a.51.51,0,0,0,.52-.51V18.2a.52.52
          ,0,0,0-.52-.52H8.73a.52.52,0,0,0-.52.52v3.07a.51.51,0,0,0
          ,.52.51H10.2v1.56H8.06a.52.52,0,0,0-.52.52V25.8a.52.52,0,0
          ,0,.52.52h.68v1.75h-2a.48.48,0,0,0-.48.48v.11a.48.48,0,0,0
          ,.48.48H29.3a.48.48,0,0,0,.48-.48v-.11A.48.48,0,0,0,29.3
          ,28.08Zm-4.61-6.89v2.5a3.2,3.2,0,0,1-2.55-3.12,3.25,3.25
          ,0,0,1,1.65-2.81.64.64,0,0,0,.25-.86l-.11-.12a1.9,1.9,0,0
          ,1,.09-2.7,1.91,1.91,0,0,1,2.7,2.7.58.58,0,0,0,.16,1,3.21
          ,3.21,0,0,1,1.25,4.35A3.28,3.28,0,0,1,26,23.69v-2.5a.64.64
          ,0,0,0-1.28,0Zm-13.42.59h4.51v1.56H11.28V21.79h0ZM9.77,26.32h7.5v1.75H9.77Z"
          transform="translate(-0.02 0)"
          fill="#fff" /></svg>
</template>
<script>
import ProjectIconTemplate from '@/mixins/ProjectIconTemplate';
export default {
  mixins: [ProjectIconTemplate],
};
</script>
import { render, staticRenderFns } from "./Icon_template_a8a8151d-c0f8-40f4-bbf0-6c70575d34b6.vue?vue&type=template&id=74d21886&"
import script from "./Icon_template_a8a8151d-c0f8-40f4-bbf0-6c70575d34b6.vue?vue&type=script&lang=js&"
export * from "./Icon_template_a8a8151d-c0f8-40f4-bbf0-6c70575d34b6.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports